
function ContactPage() {
  return (
    <div>
      This is the contact page.
    </div>
  );
}

export default ContactPage;
